<template>
  <div class="home">
    <!--    <div>-->
    <!--      <video-player-->
    <!--        class="video-player vjs-custom-skin"-->
    <!--        ref="videoPlayer"-->
    <!--        :playsline="false"-->
    <!--        :options="playerOptions"-->
    <!--      ></video-player>-->
    <!--    </div>-->
    <div v-if="!isPhone">
      <div class="main">
        <div class="box1">
          <!--        <img alt="box1-bg" src="@/assets/sy_1.jpg" >-->
          <Carousel v-model="value1" loop>
            <CarouselItem>
              <div class="box-carousel" v-lazy:background-image="sy_1">
                <div class="title">
                  <p>努力成为最专业的营销服务平台</p>
                  <p class="english">
                    STRIVE TO BECOME THE MOST PROFESSIONAL MARKETING SERVICE
                    PLATFORM
                  </p>
                  <p class="btn-group">
                    <Button class="btn" @click="goEnterprise" shape="circle"
                      >企业端</Button
                    >
                    <Button class="btn" @click="goPlat" shape="circle"
                      >平台端</Button
                    >
                    <Button class="btn" @click="goPersonal" shape="circle"
                      >个人端</Button
                    >
                  </p>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
        <div class="box2">
          <div class="box2-position">
            <p class="english">
              What is Jin Xia Rong Tong and what do we want to do？
            </p>
            <p class="title color-2f6daf">金侠荣通是什么？</p>
            <p>
              金侠荣通经工商注册，税务部门批准，于2021年4月正式运营，努力将线下营销服务业务流程标化，应用互联网工具移至线上，全面系统的解决营销外包企业、灵活就业营销服务人员的工作效能，重构新业态，促进企业高质量发展。
            </p>
            <p>
              金侠荣通将始终遵循“诚信、友善、利他、认同的核心价值观，“诚信、负责、共赢、感恩”的合作宗旨，“合规、安全、共通、共享”的经营理念，携手会员一道，创新升级营销外包行业，重构新业态，努力成为最专业的营销服务平台，努力实现政府、企业、灵活就业人员共通、共享、共赢的新格局。
            </p>
            <div class="dec">
              <Row class="content" type="flex" justify="space-between">
                <Col span="7">
                  <img v-lazy="img1_21" alt="使命" />
                  <p class="title color-f8a427">使命</p>
                  <p class="font-12">
                    应用互联网工具，努力提升营销外包企业、灵活就业营销服务人员的工作效能，重构新业态，促进企业高质量发展
                  </p>
                </Col>
                <Col span="7">
                  <img v-lazy="img1_19" alt="定位" />
                  <p class="title color-f8a427">定位</p>
                  <p>努力成为最专业的营销服务平台</p>
                </Col>
                <Col span="7">
                  <img v-lazy="img1_23" alt="愿景" />
                  <p class="title color-f8a427">愿景</p>
                  <p>
                    努力实现政府、企业、灵活就业人员共通、共享、共赢的新格局
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div class="box3" v-lazy:background-image="sy_2">
          <Row class="content" type="flex" justify="center">
            <Col span="6" class="border-6da3d2 padding-3rem">
              <p class="title">合作宗旨</p>
              <div class="dec">
                <p class="english">PRINCIPLE</p>
                <p class="text">诚信、负责、共赢、感恩</p>
              </div>
            </Col>
            <Col span="6" class="border-6da3d2 padding-3rem">
              <p class="title">核心价值观</p>
              <div class="dec">
                <p class="english">VALUES</p>
                <p class="text">诚信、友善、利他、认同</p>
              </div>
            </Col>
            <Col span="6" class="padding-3rem">
              <p class="title">经营理念</p>
              <div class="dec">
                <p class="english">PHILOSOPHY</p>
                <p class="text">合规、安全、共通、共享</p>
              </div>
            </Col>
          </Row>
        </div>
        <div class="box4">
          <div>
            <p class="english">REGISTERED MEMBERS</p>
            <p class="title color-2f6daf">注册会员</p>
          </div>
          <Row class="content">
            <Col span="10" class="left margin-right-1rem">
              <div class="text">
                <div class="aa">
                  <p>真诚的欢迎您注册成为金侠荣通会员</p>
                  <p class="english">
                    WELCOME TO REGISTER AS MEMBER OF JINXIA RONGTONG
                  </p>
                </div>
              </div>
              <div class="video-personal" @click="openModal">
                <div class="color-fff video-dec">
                  <p>个体注册会员</p>
                  <p class="margin-top-1rem font-14">
                    INDIVIDUAL REGISTERED MEMBER
                  </p>
                </div>
                <p class="personal" v-lazy:background-image="sy_6"></p>
                <!--                      <video-player-->
                <!--                              class="video-player vjs-custom-skin"-->
                <!--                              ref="videoPlayer"-->
                <!--                              :playsline="false"-->
                <!--                              :options="playerOptions"-->
                <!--                      ></video-player>-->
              </div>
            </Col>
            <Col flex="auto" class="right">
              <!--                  <p class="video-enterprise">-->
              <!--                      <video-player-->
              <!--                              class="video-player vjs-custom-skin"-->
              <!--                              ref="videoPlayerEnterprise"-->
              <!--                              :playsline="true"-->
              <!--                              :options="playerOptionsEnterprise"-->
              <!--                      ></video-player>-->
              <!--                  </p>-->
              <div class="enterprise-img">
                <div class="color-fff video-dec">
                  <p>企业注册会员</p>
                  <p class="margin-top-1rem font-14">
                    INDIVIDUAL REGISTERED MEMBER
                  </p>
                </div>
                <p class="enterprise" v-lazy:background-image="sy_5"></p>
              </div>
              <!--                  <img src="@/assets/sy_6.png" width="100%" alt="">-->
              <div class="dec">
                <Row class="margin-top-1rem">
                  <Col span="11" class="dec1">
                    <p class="margin-bottom-1rem dec-text">
                      让我们携手一道，共筑财税安全，全面提升营销外包领域效能
                    </p>
                    <p class="english">FINANCICAL SECURITY</p>
                  </Col>
                  <Col flex="auto" class="dec2">
                    <p class="margin-bottom-1rem dec-text">
                      重构新业态，促进企业高质量发展
                    </p>
                    <p class="english">HIGH QUALITY DEVELOPMENT</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div class="box5">
          <div class="title">
            <p>会员限制</p>
            <!--              <p class="border-liner"></p>-->
            <p class="english">
              <span class="border-liner-right"></span>MEMBERS TO LIMIT<span
                class="border-liner-left"
              ></span>
            </p>
          </div>
          <div class="content">
            <Row>
              <Col flex="auto" class="margin-right-1rem">
                <Row class="bg-fff padding-2rem">
                  <Col>
                    <img v-lazy="img1_72" alt="行业限制" />
                  </Col>
                  <Col offset="1">
                    <p class="text">行业限制</p>
                    <p class="color-b6b6b6 margin-top-05rem">
                      建筑、房地产行业
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span="17">
                <Row class="bg-fff">
                  <Col span="4">
                    <img class="padding-2rem" v-lazy="img1_77" alt="人员限制" />
                  </Col>
                  <Col span="18" class="padding-top-2rem">
                    <p class="text">人员限制</p>
                    <p class="color-b6b6b6 margin-top-05rem">
                      军人、公职人员等国家法律法规和纪律规定禁止从事兼职或经商人员不能注册会员；企业全日制员工不能注册会员，包括不限于法定代表人、股东、董事、监事。
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row class="margin-top-1rem">
              <Col flex="auto" class="margin-right-1rem">
                <Row class="bg-fff padding-2rem">
                  <Col class="">
                    <img v-lazy="img1_100" alt="年龄限制" />
                  </Col>
                  <Col offset="1">
                    <p class="text">年龄限制</p>
                    <p class="color-b6b6b6 margin-top-05rem">
                      年龄不得超过60周岁
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span="17">
                <Row class="bg-fff">
                  <Col span="4">
                    <img
                      class="padding-2rem"
                      v-lazy="img1_104"
                      alt="经营限制"
                    />
                  </Col>
                  <Col span="18" class="padding-top-2rem">
                    <p class="text">经营限制</p>
                    <p class="color-b6b6b6 margin-top-05rem">
                      严禁将全日制用工、高管薪酬、直接带货、微商销售、财产转让（含股权转让）、财产租赁，利息股息红利等套用灵活用工政策，和借着灵活用工名义虚开发票、对私转账等行为。
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div class="box6">
          <div class="content">
            <Row type="flex" justify="end" align="middle">
              <Col span="20">
                <img v-lazy="sy_7" alt="" />
              </Col>
              <Col span="4">
                <p class="contact">
                  <img v-lazy="sy_8" alt="" /><span class="number"
                    >13657351431</span
                  >
                </p>
              </Col>
            </Row>
          </div>
          <p class="color-bdc2c9 text-center">备案号：湘ICP备2021005507号</p>
        </div>
      </div>
      <div class="fix-content" @mouseout="hiddenNumber">
        <p class="fix-contact" @mouseover="showNumber">
          <img v-lazy="sy_8" width="16" alt="" />电话咨询
        </p>
      </div>
      <div class="fix-number" v-if="isShowNumber">
        <p class="color-666666">联系电话</p>
        <p class="color-f8a427">13657351431</p>
      </div>
      <Modal
        v-model="videoModal"
        width="860"
        @on-cancel="cancleModal"
        footer-hide
      >
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsline="false"
          :options="playerOptions"
        ></video-player>
      </Modal>
    </div>
    <div v-else>
      <div class="main-phone">
        <div class="box1-phone">
          <!--        <img alt="box1-bg" src="@/assets/sy_1.jpg" >-->
          <Carousel v-model="value1" loop>
            <CarouselItem>
              <div class="box1-phone-carousel" v-lazy:background-image="imgy_1">
                <div class="title color-fff">
                  <p class="font-18 bolder">努力成为最专业的营销服务平台</p>
                  <p class="phone-btn-group">
                    <Button
                      class="phone-btn"
                      @click="goEnterprise"
                      shape="circle"
                      >企业端</Button
                    >
                    <Button class="phone-btn" @click="goPlat" shape="circle"
                      >平台端</Button
                    >
                    <Button class="phone-btn" @click="goPersonal" shape="circle"
                      >个人端</Button
                    >
                  </p>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
        <div class="box2-phone">
          <div class="box2-position-phone">
            <p class="bolder">What is Jin Xia Rong Tong ？</p>
            <p class="box2-phone-title color-2f6daf">金侠荣通是什么？</p>
            <p>
              金侠荣通经工商注册，税务部门批准，于2021年4月正式运营，努力将线下营销服务业务流程标化，应用互联网工具移至线上，全面系统的解决营销外包企业、灵活就业营销服务人员的工作效能，重构新业态，促进企业高质量发展。
            </p>
            <p>
              金侠荣通将始终遵循“诚信、友善、利他、认同的核心价值观，“诚信、负责、共赢、感恩”的合作宗旨，“合规、安全、共通、共享”的经营理念，携手会员一道，创新升级营销外包行业，重构新业态，努力成为最专业的营销服务平台，努力实现政府、企业、灵活就业人员共通、共享、共赢的新格局。
            </p>
            <div class="dec">
              <Row class="box2-phone-content" type="flex">
                <Col span="5">
                  <img width="40" v-lazy="img1_19" alt="定位"
                /></Col>
                <Col span="18">
                  <p class="title color-f8a427">定位</p>
                  <p class="font-12">努力成为最专业的营销服务平台</p>
                </Col>
              </Row>
              <Row type="flex" class="box2-phone-content">
                <Col span="5">
                  <img width="40" v-lazy="img1_21" alt="使命"
                /></Col>
                <Col span="18">
                  <p class="title color-f8a427">使命</p>
                  <p class="font-12">
                    应用互联网工具，努力提升营销外包企业、灵活就业营销服务人员的工作效能，重构新业态，促进企业高质量发展
                  </p>
                </Col>
              </Row>
              <Row type="flex" class="box2-phone-content">
                <Col span="5">
                  <img width="40" v-lazy="img1_23" alt="愿景"
                /></Col>
                <Col span="18">
                  <p class="title color-f8a427">愿景</p>
                  <p class="font-12">
                    努力实现政府、企业、灵活就业人员共通、共享、共赢的新格局
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div class="box3-phone" v-lazy:background-image="imgy_2">
          <div class="box3-phone-content">
            <p class="font-14 bolder"><span class="dot"></span>合作宗旨</p>
            <p class="box3-phone-dec">诚信、负责、共赢、感恩</p>
          </div>
          <div class="box3-phone-content">
            <p class="font-14 bolder"><span class="dot"></span>核心价值观</p>
            <p class="box3-phone-dec">诚信、友善、利他、认同</p>
          </div>
          <div class="box3-phone-content">
            <p class="font-14 bolder"><span class="dot"></span>经营理念</p>
            <p class="box3-phone-dec">合规、安全、共通、共享</p>
          </div>
        </div>
        <div class="box4-phone">
          <div class="box4-phone-register text-center">
            <p class="padding-top-2rem">REGISTERED MEMBERS</p>
            <p class="font-18 bolder margin-top-05rem color-2f6daf">注册会员</p>
          </div>
          <div class="box4-phone-text" v-lazy:background-image="imgy_3">
            <p class="font-14 bolder">真诚的欢迎您注册成为金侠荣通会员</p>
            <p class="english">WELCOME TO JINXIA RONGTONG</p>
          </div>
          <!--                  <img v-lazy="imgy_3" alt="企业图片" >-->
          <!--                  <img v-lazy="imgy_6" alt="个人图片" >-->
          <div class="box4-phone-plant" v-lazy:background-image="sy_5">
            <div class="color-fff phone-video-dec">
              <p>企业注册会员</p>
              <p class="margin-top-05rem font-12">
                INDIVIDUAL REGISTERED MEMBER
              </p>
            </div>
          </div>
          <div class="box4-phone-plant" v-lazy:background-image="imgy_6">
            <div class="color-fff phone-video-dec">
              <p>个人注册会员</p>
              <p class="margin-top-05rem font-12">
                INDIVIDUAL REGISTERED MEMBER
              </p>
            </div>
          </div>
          <div class="box4-phone-dec color-fff">
            <p>
              让我们携手一道，共筑财税安全，全面提升营销外包领域效能,重构新业态，促进企业高质量发展
            </p>
            <p class="font-14">FINANCICAL SECURITY</p>
          </div>
        </div>
        <div class="box5-phone">
          <div class="box5-phone-title">
            <p>会员限制</p>
            <p class="box5-phone-english">
              <span class="box5-phone-liner-right"></span>MEMBERS TO LIMIT<span
                class="box5-phone-liner-left"
              ></span>
            </p>
          </div>
          <div class="margin-top-1rem">
            <div class="box5-phone-content">
              <div class="box5-phone-img">
                <img width="40" v-lazy="img1_72" alt="行业限制" />
              </div>
              <div class="box5-phone-text">
                <p class="font-14 bolder">行业限制</p>
                <p class="color-b6b6b6 margin-top-05rem">建筑、房地产行业</p>
              </div>
            </div>
            <div class="box5-phone-content">
              <div class="box5-phone-img">
                <img width="40" v-lazy="img1_100" alt="年龄限制" />
              </div>
              <div class="box5-phone-text">
                <p class="font-14 bolder">年龄限制</p>
                <p class="color-b6b6b6 margin-top-05rem">年龄不得超过60周岁</p>
              </div>
            </div>
            <div class="box5-phone-content">
              <div class="box5-phone-img">
                <img
                  class="padding-bottom-5rem"
                  width="40"
                  v-lazy="img1_77"
                  alt="人员限制"
                />
              </div>
              <div class="box5-phone-text">
                <p class="font-14 bolder">人员限制</p>
                <p class="color-b6b6b6 margin-top-05rem">
                  军人、公职人员等国家法律法规和纪律规定禁止从事兼职或经商人员不能注册会员；企业全日制员工不能注册会员，包括不限于法定代表人、股东、董事、监事。
                </p>
              </div>
            </div>
            <div class="box5-phone-content">
              <div class="box5-phone-img">
                <img
                  class="padding-bottom-4-6rem"
                  width="40"
                  v-lazy="img1_104"
                  alt="人员限制"
                />
              </div>
              <div class="box5-phone-text">
                <p class="font-14 bolder">经营限制</p>
                <p class="color-b6b6b6 margin-top-05rem">
                  严禁将全日制用工、高管薪酬、直接带货、微商销售、财产转让（含股权转让）、财产租赁，利息股息红利等套用灵活用工政策，和借着灵活用工名义虚开发票、对私转账等行为。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="box6-phone">
          <div class="box6-phone-content">
            <Row type="flex" justify="center" align="middle">
              <Col span="8">
                <img width="120" v-lazy="sy_7" alt="" />
              </Col>
              <Col span="8" offset="4">
                <p class="box6-phone-contact">
                  <img width="20" v-lazy="sy_8" alt="" /><span
                    class="box6-phone-number"
                    >13657351431</span
                  >
                </p>
              </Col>
            </Row>
          </div>
          <p class="color-bdc2c9 margin-top-05rem text-center">
            备案号：湘ICP备2021005507号
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      img1_19: require("@/assets/1_19.png"),
      img1_21: require("@/assets/1_21.png"),
      img1_23: require("@/assets/1_23.png"),
      img1_72: require("@/assets/1_72.png"),
      img1_77: require("@/assets/1_77.png"),
      img1_100: require("@/assets/1_100.png"),
      img1_104: require("@/assets/1_104.png"),
      imgy_1: require("@/assets/y_1.jpg"),
      imgy_2: require("@/assets/y_2.jpg"),
      imgy_3: require("@/assets/y_3.jpg"),
      imgy_6: require("@/assets/y_6.png"),
      sy_1: require("@/assets/sy_1.jpg"),
      sy_2: require("@/assets/sy_2.png"),
      sy_5: require("@/assets/sy_5.png"),
      sy_6: require("@/assets/sy_6.png"),
      sy_7: require("@/assets/sy_7.png"),
      sy_8: require("@/assets/sy_8.png"),
      width: null,
      isPhone: false,
      isShowNumber: false,
      value1: 0,
      videoModal: false,
      playerOptionsEnterprise: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../assets/faf.mp4"), // url地址
          },
        ],
        poster: require("../assets/sy_6.png"), // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../assets/faf.mp4"), // url地址
          },
        ],
        poster: require("../assets/sy_6.png"), // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.width = document.body.clientWidth;
    this.isPhone = this.width < 500 ? true : false;

    console.log(this.isPhone);
  },
  methods: {
    goEnterprise() {
      window.open("http://www.jinxiarongtong.com/Enterprise/login", "_blank");
    },
    goPlat() {
      window.open("http://www.jinxiarongtong.com/Plat/login", "_blank");
    },
    goPersonal() {
      window.open("http://www.jinxiarongtong.com/personal/login", "_blank");
    },
    openModal() {
      this.videoModal = true;
      this.player.play();
    },
    cancleModal() {
      this.player.pause();
      this.videoModal = false;
    },
    showNumber() {
      this.isShowNumber = true;
    },
    hiddenNumber() {
      this.isShowNumber = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./home.less";
</style>
