import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//引入 ViewUI
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// 清除默认样式
import '@/assets/css/reset.css';
import VueLazyload from "vue-lazyload";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import FastClick from  'fastclick'
import VideoPlayer from 'vue-video-player';
Vue.use(VideoPlayer)
Vue.use(VueLazyload)
// Vue.use(ElementUI);
FastClick.attach(document.body)
Vue.use(ViewUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
